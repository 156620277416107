<template lang="">
    <th class="border-b  font-medium p-4 pl-8 pt-0 pb-3 text-slate-400  text-left">
        <slot/>
    </th>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>