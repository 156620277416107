<template lang="">
    <table class="border-collapse table-auto w-full text-sm">
        <slot/>
    </table>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>