<template lang="">
    <td class="border-b border-slate-100  p-4 pl-8 text-slate-500 ">
        <slot/>
    </td>
</template>
<script>
export default {
    
}
</script>
<style lang="">
    
</style>